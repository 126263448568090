import request from '../utils/request.js'
// info资讯接口

// 获取资讯列表
export function getLatestNews (data) {
  return request({
    url: '/news/getLatestNewsByCategory',
    method: 'POST',
    data
  })
}

// 获取资讯详情
export function getNewsById (data) {
  return request({
    url: '/news/getNewsById',
    method: 'POST',
    data
  })
}
