<template>
  <!-- 资讯列表页 -->
  <div class="news" v-infinite-scroll="moreNews" style="overflow:auto">
    <div class="box">
      <div class="news-item-box">
        <ul class="infinite-list items">
          <li v-show="item.title!==''" v-for="item in newsList" :key="item.id">
            <el-image class="news-img" :src="item.cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline news-img"></i>
              </div>
            </el-image>
            <div class="text">
              <h3 @click="goNewsDetailsPage(item.id)" class=" hand">{{item.title}}</h3>
              <h4>{{item.subtitle}}</h4>
              <div>
                <!-- <span class="icon">{{item.source}}</span> -->
                <span>{{item.viewcount}}次阅读</span>
                <span>{{$initTime(item.publishDate)}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-backtop :bottom="80" target=".news">
      <img src="@/assets/img/index/fhdb.png">
    </el-backtop>
  </div>
</template>
<script>
import { getLatestNews } from '@/api/news.js'
import { blankPagePar } from '@/utils/index.js'
export default {
  data () {
    return {
      newsList: [], // 资讯列表数据
      // 分页数据
      pageObj: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    // 跳转到资讯详情页面
    goNewsDetailsPage (newsId) {
      // this.$router.push({ path: '/newsDetails', query: { newsId } })
      blankPagePar('newsDetails', {
        newsId
      })
    },
    //  加载更多
    moreNews () {
      this.pageObj.pageNum++
      this.getLatestNews()
    },
    // 获取资讯列表
    async getLatestNews () {
      const res = await getLatestNews({ ...this.pageObj, category: 0 })
      this.newsList = [...this.newsList, ...res.data]
    }
  },
  created () {
    this.getLatestNews()
  }
}
</script>
<style lang="less" scoped>
.news {
  position: relative;
  height: calc(100vh - 50px);
  &::-webkit-scrollbar {
    /* 滚动条容器 */
    display: none;
    width: 2px;
    background-color: transparent;
    // display: none;
  }
  &::-webkit-scrollbar-thumb {
    /* 滚动条手柄 */
    /* background-color: #00adb5; */
    background-color: #c1c1c1;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    /*滚动条的轨道*/
    border-radius: 1px;
  }
  .box {
    padding: 30px 0;
    background-color: #f5f7fb;
    .items {
      width: 900px;
      margin: 0 auto;
      li {
        display: flex;
        align-items: center;
        box-shadow: 0 0 2px 0 #ccc;
        background-color: #fff;
        border-radius: 2px;
        padding: 20px;
        margin-bottom: 15px;
        &:hover {
          box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.3);
          transition: all 0.3s;
        }
        .news-img {
          display: inline-block;
          width: 160px;
          height: 120px;
          font-size: 60px;
          margin-right: 20px;
          color: #999;
        }
        .el-icon-picture-outline {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 120px;
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          flex: 1;
          height: 120px;
          h3 {
            font-size: 18px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &:hover {
              text-decoration: underline;
            }
          }
          h4 {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          span {
            margin-right: 30px;
            font-size: 15px;
            color: #8b8b8b;
          }
        }
      }
    }
    p {
      text-align: center;
      padding: 10px 0 50px;
      font-size: 20px;
      font-weight: bold;
      color: #4b639f;
    }
  }
  .bom {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
